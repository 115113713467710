
















































































































import Vue, { PropType } from 'vue';
import Product, { Article } from '@/entities/Product';
import type { ElTable } from 'element-ui/types/table';
import { PencilAltIcon, CheckCircleIcon, XCircleIcon } from '@vue-hero-icons/outline';

export default Vue.extend({
  name: 'ProductTable',

  components: {
    PencilAltIcon,
    CheckCircleIcon,
    XCircleIcon,
  },

  props: {
    products: { type: Array as PropType<Product[]>, default: () => ([]) },
  },

  computed: {
    productsColumnsForTable(): any[] {
      return this.products.map((product) => ({
        id: product.id,
        family: product.family,
        subfamily: product.subfamily,
        previsionFamily: product.previsionFamily,
        pdfFamily: product.pdfFamily,
        name: product.name,
        isActive: product.isActive,
        isRequired: product.isRequired,
        minQuantity: product.minQuantity,
        productSource: product.productSource,
        quantityPerPlate: product.quantityPerPlate,
        articles: product.articles,
        equivalenceProductName: product.equivalenceProductName,
        equivalenceRate: product.equivalenceRate,
      }));
    },
    pluMax(): number {
      const articlesLengths = this.products?.map((product) => product?.articles?.length);
      const max = articlesLengths.length ? Math.max(...articlesLengths) : 0;
      return Math.max(max, 5);
    },
  },
  watch: {
    async pluMax() {
      await this.$nextTick();
      (this.$refs.productTable as ElTable).doLayout();
    },
  },
  methods: {
    handleEdit(row): void {
      this.$emit('edit-product', this.products.find((product) => product.id === row.id));
    },
    trunkateId(id): string {
      return `${id.slice(0, 5)}...`;
    },
    showCorrectPluOrNothing(product, count, propertyName): string {
      const articles: Article[] = product?.articles ?? [];
      if (count <= articles.length) {
        const index = count - 1;
        return articles[index][propertyName].toString();
      }
      return '-';
    },
  },

});
