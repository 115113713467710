








































import _ from 'lodash';
import Vue, { PropType } from 'vue';
import Sortable from '@/components/Sortable.vue';

export default Vue.extend({
  name: 'SortingModal',

  components: {
    Sortable,
  },

  props: {
    products: { type: Array as PropType<any[]>, default: () => ([]) },
    loading: { type: Boolean },
  },

  data(): {
    visible: boolean,
    sortableProducts: any[], } {
    return {
      visible: false,
      sortableProducts: [],
    };
  },

  computed: {

  },

  mounted() {
    this.sortableProducts = this.products;
    this.initSortingPositionForFirstTime();
    this.visible = true;
  },

  methods: {
    handleClose(): void {
      this.visible = false;
      this.$emit('close');
    },
    initSortingPositionForFirstTime() {
      const productsWithPositionZero = this.sortableProducts.filter((product) => product.sortingPosition === 0);
      if (productsWithPositionZero.length > 1) {
        this.sortableProducts = this.sortableProducts.map((product, index) => ({ ...product, sortingPosition: index }));
      }
    },
    handleReorderFamiliesButton(orderDirection) {
      this.sortableProducts = _.orderBy(this.sortableProducts, ['previsionFamily'], orderDirection).map((product, index) => ({ ...product, sortingPosition: index }));
    },
    handleDraggableChange({ oldIndices, newIndices }) {
      const sortedByPosition = _.sortBy(this.sortableProducts, 'sortingPosition');
      for (let i = 0; i < oldIndices.length; i += 1) {
        const oldIndex = oldIndices[i];
        const newIndex = newIndices[i];
        const elem = this.sortableProducts.find((e) => e.sortingPosition === oldIndex);
        const elemIndex = this.sortableProducts.findIndex((e) => e.sortingPosition === oldIndex);
        sortedByPosition.splice(elemIndex, 1);
        sortedByPosition.splice(newIndex, 0, elem);

        sortedByPosition.forEach((e, index) => {
          // eslint-disable-next-line no-param-reassign
          e.sortingPosition = index;
        });
        this.sortableProducts = sortedByPosition;
      }
      console.log(this.sortableProducts);
    },
    handleSaveButton() {
      const lightProducts = this.sortableProducts.map(
        (product) => ({ productId: product.id, sortingPosition: product.sortingPosition }),
      );
      this.$emit('save', lightProducts);
    },
    handleCancelButton() {
      this.$emit('close');
    },
  },

});
