

















































































































































































































import { cloneDeep as _cloneDeep } from 'lodash';
import Vue, { PropType } from 'vue';
import Product, {
  Article,
  ProductSource,
} from '@/entities/Product';
import { PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline';

type ProductForm = Omit<Product, 'productSource' | 'articles'> & {
  productSource: ProductSource | null,
  articles: Partial<Article>[],
};

export default Vue.extend({
  name: 'UpsertModal',

  components: { PlusCircleIcon, XCircleIcon },

  props: {
    product: {
      type: Object as PropType<ProductForm>,
      default: () => ({
        id: '',
        family: '',
        subfamily: '',
        previsionFamily: '',
        pdfFamily: '',
        name: '',
        isActive: false,
        isRequired: false,
        minQuantity: 0,
        productSource: null,
        equivalenceRate: null,
        equivalenceProductName: null,
        quantityPerPlate: 1,
        sortingPosition: 0,
        articles: [],
      }),
    },
    families: { type: Array as PropType<string[]>, default: () => ([]) },
    subfamilies: { type: Array as PropType<string[]>, default: () => ([]) },
    previsionFamilies: { type: Array as PropType<string[]>, default: () => ([]) },
    pdfFamilies: { type: Array as PropType<string[]>, default: () => ([]) },
    productNames: { type: Array as PropType<string[]>, default: () => ([]) },
  },

  data(): {
    visible: boolean,
    form: ProductForm,
    } {
    return {
      visible: false,
      form: _cloneDeep(this.product),
    };
  },

  computed: {
    isCreate(): boolean {
      return !this.product?.id;
    },
    isUpdate(): boolean {
      return !!this.product?.id;
    },
    hasIncompletePlu(): boolean {
      return this.form.articles.some((article) => !article.name?.trim() || !article.plu || !article.quantity);
    },
    baguetteEquivalentIsValid(): boolean {
      // TODO: vérifier si un product name est valide pour calculer l'équivalence
      const isEquivalenceNameValid = !!this.form?.equivalenceProductName;
      if (isEquivalenceNameValid && this.form?.equivalenceRate) {
        return true;
      }
      return !this.form?.equivalenceProductName;
    },
    isFormIncomplete(): boolean {
      return !this.form.name
        || !this.form.family
        || !this.form.productSource
        || !this.form.articles.length
        || !this.baguetteEquivalentIsValid
        || (!!this.form.articles.length && this.hasIncompletePlu);
    },
  },

  mounted() {
    this.initForm();
    this.visible = true;
  },

  methods: {
    initForm(): void {
      this.form = _cloneDeep(this.product);
    },
    handleEquivalenceChange() {
      if (!this.form?.equivalenceProductName) {
        this.form.equivalenceProductName = null;
        this.form.equivalenceRate = null;
      }
    },
    handleClose(): void {
      this.visible = false;
      this.$emit('close');
    },
    handleAddPlu(): void {
      if (!this.hasIncompletePlu) {
        this.form.articles.push({
          name: this.form.name,
          plu: undefined,
          quantity: undefined,
        });
      }
    },
    handleRemovePlu(index): void {
      this.form.articles.splice(index, 1);
    },
    handleSaveButton(): void {
      const form = {
        ...this.form,
        articles: this.form.articles.map(
          (article) => ({ ...article, name: article.name?.trim() }),
        ),
      };
      if (this.isUpdate) {
        this.$emit('update', form);
      } else {
        this.$emit('create', { ...form, id: undefined });
      }
    },
  },

});
