



















































import Vue from 'vue';
import { DocumentAddIcon, ClockIcon } from '@vue-hero-icons/outline';
import services from '@/services/server';

export default Vue.extend({
  name: 'ImportProductsButton',

  components: {
    DocumentAddIcon,
    ClockIcon,
  },

  data(): {
    uploadUrl: string,
    showDialog: boolean,
    isFileImporting: boolean,
    isFileImported: boolean,
    isFileImportFailed: boolean,
    errorDetailedMessage: string,
    } {
    return {
      uploadUrl: `${process.env.VUE_APP_SERVER_API_BASE_URL}/products/import/xlsx`,
      showDialog: false,
      isFileImporting: false,
      isFileImported: false,
      isFileImportFailed: false,
      errorDetailedMessage: '',
    };
  },
  computed: {
    headers() {
      return {
        ...services.authService.getAuthHeaders(),
        'Content-Type': 'multipart/form-data',
      };
    },
  },
  methods: {
    async handleUpload(request) {
      this.showDialog = true;
      this.isFileImporting = true;
      this.isFileImported = false;
      this.isFileImportFailed = false;
      try {
        const formData = new FormData();
        formData.append('file', request.file);
        const result = await services.backOfficeService.importProductsFromExcelFile(formData);
        if (result.isImported) {
          this.isFileImported = true;
          this.$emit('file-imported');
        } else {
          throw new Error('UNKNOWN_ERROR');
        }
      } catch (error: any) {
        this.isFileImportFailed = true;
        this.errorDetailedMessage = '';
        const errorData = error?.response?.data;
        if (errorData?.status === 'NO_SUCH_SHEET') {
          const franchiseSheetError = errorData?.message.includes('FRANCHISE CENTRALE');
          if (franchiseSheetError) {
            this.errorDetailedMessage = 'La page FRANCHISE CENTRALE n\'est pas présente dans le fichier Excel.';
          } else {
            this.errorDetailedMessage = errorData.message;
          }
        }
        if (errorData?.status === 'MISSING_MANDATORY_COLUMN') {
          const numberOfColumns = errorData.details?.missingColumnNames?.length;
          const columnNames = errorData.details?.missingColumnNames.join();
          if (numberOfColumns === 1) {
            this.errorDetailedMessage = `La colonne ${columnNames} n'est pas présente dans le fichier Excel.`;
          }
          if (numberOfColumns > 1) {
            this.errorDetailedMessage = `Les colonnes ${columnNames} ne sont pas présentes dans le fichier Excel.`;
          }
        }
      } finally {
        this.isFileImporting = false;
      }
    },
  },
});
