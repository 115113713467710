






























































import Vue, { PropType } from 'vue';
import Sortable, { MultiDrag, Swap } from 'sortablejs';
import { MenuAlt4Icon } from '@vue-hero-icons/outline';

let sortable: Sortable | null = null;
const selected = new Set<string>();

export default Vue.extend({
  name: 'Sortable',
  props: {
    products: { type: Array as PropType<any[]>, default: () => ([]) },
    loading: { type: Boolean },
  },
  components: { MenuAlt4Icon },
  data(): { displaySelected: boolean } {
    return {
      displaySelected: false,
    };
  },
  beforeDestroy() {
    if (sortable) {
      sortable.destroy();
      sortable = null;
    }
  },
  methods: {
    computedSelected() {
      if (selected.size) {
        this.displaySelected = true;
      } else {
        this.displaySelected = false;
      }
    },
  },
  mounted() {
    const ref = this.$refs.sortable;
    if (!sortable) {
      try {
        Sortable.mount(new MultiDrag(), new Swap());
      } catch (e) {
        console.log('EXPLOEDED');
      }
      sortable = Sortable.create(ref as HTMLElement, {
        multiDrag: true, // Enable the plugin
        selectedClass: 'sortable-selected', // Class name for selected item
        ghostClass: 'ghost',
        animation: 150,
        // Element is chosen
        onSelect: (evt) => {
          const productId = evt.item.getAttribute('data-product-id');
          if (productId) {
            selected.add(productId);
            this.computedSelected();
          }
        },
        onDeselect: (evt) => {
          const productId = evt.item.getAttribute('data-product-id');
          if (productId) {
            selected.delete(productId);
            this.computedSelected();
          }
        },
        onSort: (evt) => {
          // This event may contain one index OR multiple indices depending on the event type.
          // for more simplicity, we only expose an array of old and new indices.
          const oldIndices = evt.oldIndicies?.length ? [...evt.oldIndicies.map((e) => e.index)] : [evt.oldIndex];
          const newIndices = evt.newIndicies?.length ? [...evt.newIndicies.map((e) => e.index)] : [evt.newIndex];
          this.$emit('update', { oldIndices, newIndices });
        },
      });
    }
  },
});
