var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"productTable",staticClass:"w-full rounded-md",attrs:{"data":_vm.productsColumnsForTable,"default-sort":{prop: 'sortingPosition', order: 'ascending'},"height":"100%"}},[_c('el-table-column',{attrs:{"label":"Éditer","width":"70","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('PencilAltIcon',{staticClass:"inline-block text-blue-500 hover:text-blue-700 hover:cursor-pointer",attrs:{"size":"1.4x"},on:{"click":function($event){return _vm.handleEdit(row)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Name","sortable":"","fixed":"","min-width":"300"}}),_c('el-table-column',{attrs:{"prop":"family","label":"Famille","sortable":"","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"previsionFamily","label":"Famille prévision","sortable":"","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"pdfFamily","label":"Famille PDF","sortable":"","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"isActive","label":"Actif","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CheckCircleIcon',{directives:[{name:"show",rawName:"v-show",value:(row.isActive),expression:"row.isActive"}],staticClass:"inline-block text-green-600",attrs:{"size":"1.4x"}}),_c('XCircleIcon',{directives:[{name:"show",rawName:"v-show",value:(!row.isActive),expression:"!row.isActive"}],staticClass:"inline-block text-red-500",attrs:{"size":"1.4x"}})]}}])}),_c('el-table-column',{attrs:{"prop":"isRequired","label":"Oblig.","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CheckCircleIcon',{directives:[{name:"show",rawName:"v-show",value:(row.isRequired),expression:"row.isRequired"}],staticClass:"inline-block text-green-600",attrs:{"size":"1.4x"}}),_c('XCircleIcon',{directives:[{name:"show",rawName:"v-show",value:(!row.isRequired),expression:"!row.isRequired"}],staticClass:"inline-block text-red-500",attrs:{"size":"1.4x"}})]}}])}),_c('el-table-column',{attrs:{"prop":"equivalenceProductName","label":"Equivalence Produit","align":"center","width":"200"}}),_c('el-table-column',{attrs:{"prop":"equivalenceRate","label":"Ratio Eq.","align":"center","width":"90"}}),_vm._l((_vm.pluMax),function(count){return _c('el-table-column',{key:("key_plu_" + count),attrs:{"prop":"plu","label":("PLU " + count),"align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.showCorrectPluOrNothing(row, count, 'plu'))+" ")]}}],null,true)})}),_vm._l((_vm.pluMax),function(count){return _c('el-table-column',{key:("key_quantity_" + count),attrs:{"prop":"quantity","label":("x_PLU" + count),"align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.showCorrectPluOrNothing(row, count, 'quantity'))+" ")]}}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }