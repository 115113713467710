













import Vue from 'vue';
import { DocumentDownloadIcon } from '@vue-hero-icons/outline';

export default Vue.extend({
  name: 'ImportProductsButton',

  components: {
    DocumentDownloadIcon,
  },

});
