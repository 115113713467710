



































































































import Vue from 'vue';
import { uniq as _uniq } from 'lodash';
import { PlusIcon, ClockIcon, SwitchVerticalIcon } from '@vue-hero-icons/outline';
import services from '@/services/server';
import Product, { ProductSource } from '@/entities/Product';
import ProductTable from '@/components/BackOffice/ProductTable.vue';
import UpsertModal from '@/components/BackOffice/UpsertModal.vue';
import SortingModal from '@/components/BackOffice/SortingModal.vue';
import ImportProductsButton from '@/components/BackOffice/ImportProductsButton.vue';
import ExportProductsButton from '@/components/BackOffice/ExportProductsButton.vue';

export default Vue.extend({
  name: 'AdminProductsPage',

  components: {
    ProductTable,
    UpsertModal,
    SortingModal,
    PlusIcon,
    ClockIcon,
    SwitchVerticalIcon,
    ImportProductsButton,
    ExportProductsButton,
  },

  data(): {
    loading: boolean,
    products: Product[],
    productToEdit?: Product,
    isUpsertModalVisible: boolean,
    isSortingModalVisible: boolean,
    searchQuery: string,
    searchProductSource: ProductSource,
    } {
    return {
      loading: false,
      products: [],
      productToEdit: undefined,
      isUpsertModalVisible: false,
      isSortingModalVisible: false,
      searchQuery: '',
      searchProductSource: ProductSource.PREPARATION,
    };
  },
  computed: {
    filteredProductsBySource(): Product[] {
      return this.products.filter((product) => product.productSource === this.searchProductSource);
    },
    filteredProducts(): Product[] {
      const query = this.searchQuery.toLowerCase().trim();
      if (query) {
        const filteredProducts = this.filteredProductsBySource.filter(
          (product) => product.name.toLowerCase().trim().includes(query)
            || product.family.toLowerCase().trim().includes(query)
            || product.subfamily?.toLowerCase().trim().includes(query)
            || product.minQuantity.toString().includes(query)
            || product.productSource.toLowerCase().trim().includes(query),
        );
        return filteredProducts;
      }
      return this.filteredProductsBySource;
    },
    families(): string[] {
      return _uniq(this.products.map((product) => product.family));
    },
    subfamilies(): string[] {
      return _uniq(this.products.map((product) => product.subfamily));
    },
    previsionFamilies(): string[] {
      return _uniq(this.products.map((product) => product.previsionFamily));
    },
    pdfFamilies(): string[] {
      return _uniq(this.products.map((product) => product.pdfFamily));
    },
    productNames(): string[] {
      return _uniq(this.products.map((product) => product.name));
    },
  },

  async mounted() {
    this.products = await this.fetchProducts();
  },
  methods: {
    async fetchProducts(): Promise<Product[]> {
      this.loading = true;
      const products = await services.backOfficeService.getProducts();
      this.loading = false;
      return products;
    },
    async handleFileImported(): Promise<void> {
      this.products = await this.fetchProducts();
    },
    handleCreateProduct(): void {
      this.productToEdit = undefined;
      this.isUpsertModalVisible = true;
    },
    handleEditProduct(product): void {
      this.productToEdit = product;
      this.isUpsertModalVisible = true;
    },
    handleSorting(): void {
      this.isSortingModalVisible = true;
    },
    async handleSortingSave(sortedProducts): Promise<void> {
      console.log('SAVED', sortedProducts);
      this.loading = true;
      await services.backOfficeService.sortProducts(sortedProducts);
      this.products = await this.fetchProducts();
      this.isSortingModalVisible = false;
      this.loading = false;
    },
    closeModal(): void {
      this.isUpsertModalVisible = false;
    },
    closeSortingModal(): void {
      this.isSortingModalVisible = false;
    },
    handleModalClosed(): void {
      this.productToEdit = undefined;
    },
    async createProduct(product) {
      this.loading = true;
      try {
        await services.backOfficeService.createProduct(product);
        this.products = await this.fetchProducts();
        this.closeModal();
      } catch (error: any) {
        if (error?.response?.data?.status === 'PRODUCT_ALREADY_EXISTS') {
          this.$alert('Un autre produit de la même famille existe déjà avec ce nom. Merci de saisir un autre nom.', 'Erreur', {
            confirmButtonText: 'Fermer',
            type: 'error',
          });
        } else {
          this.$alert('Une erreur est survenue pendant la sauvegarde du produit', 'Erreur', {
            confirmButtonText: 'Fermer',
          });
        }
      }
      this.loading = false;
    },
    async updateProduct(product) {
      this.loading = true;
      try {
        await services.backOfficeService.updateProduct(product);
        this.products = await this.fetchProducts();
        this.closeModal();
      } catch (error: any) {
        if (error?.response?.data?.status === 'PRODUCT_ALREADY_EXISTS') {
          this.$alert('Un autre produit de la même famille existe déjà avec ce nom. Merci de saisir un autre nom.', 'Erreur', {
            confirmButtonText: 'Fermer',
            type: 'error',
          });
        } else {
          this.$alert('Une erreur est survenue pendant la sauvegarde du produit', 'Erreur', {
            confirmButtonText: 'Fermer',
          });
        }
      }
      this.loading = false;
    },
    async openUrlInNewTab(url) {
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    },

    async handleDownloadProductsExcel() {
      const url = await services.backOfficeService.getDownloadProductsExcelUrl();
      this.openUrlInNewTab(url);
    },
  },
});
